<template>
  <div class="form_box">
    <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm" :rules="rules">
      <el-form-item label="菜单名称" prop="menuName">
        <el-input v-model="ruleForm.menuName" placeholder="请输入标题"></el-input>
      </el-form-item>
      <el-form-item label="是否显示" prop="display">
        <el-switch v-model="ruleForm.visible" active-value="0" inactive-value="1" active-color="#13ce66"
          inactive-color="#ff4949">
        </el-switch>
      </el-form-item>
      <el-form-item label="一级菜单" prop="menuType">
        <el-radio-group v-model="ruleForm.menuType">
          <el-radio label="M">是</el-radio>
          <el-radio label="C">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="所属菜单" prop="belongMenu">
        <el-select v-model="ruleForm.belongMenu" :disabled="ruleForm.menuType == 'M'">
          <el-option v-for="item in mmenuOptions" :key="item.label" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="控制器名称" prop="controllerName" placeholder="一级菜单无需填写控制器名">
        <el-input v-model="ruleForm.path" :disabled="ruleForm.menuType == 'M' || operateBox.operate == 'edit'"
          placeholder="一级菜单无需填写控制器名"></el-input>
      </el-form-item>
      <el-form-item label="排序" prop="orderNum">
        <el-input type="number" v-model="ruleForm.orderNum"></el-input>
      </el-form-item>
      <el-form-item label="菜单图标" prop="menuIcon">
        <span class="iconfont" :class="ruleForm.icon" style="color: #02866a;font-size: 26px"></span>
        <div class="icon_box"><span class="iconfont" :class="item" v-for="item in iconList"
            @click="getIcon(item)"></span></div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import api from "@/api/index";
import debounce from 'lodash/debounce';
export default {
  name: "edit-from",
  props: {
    operateBox: {
      type: Object,
      required: true,
    },
    mmenuOptions: {
      type: Array,
      require: true,
    },
  },
  data() {
    return {
      ruleForm: {
        menuName: "",
        visible: "0",
        menuType: "M",
        belongMenu: "",
        orderNum: 1,
        icon: "",
      },
      rules: {
        menuName: [
          { required: true, message: "请输入菜单名称", trigger: "blur" },
        ],
      },
      iconList: ['icon-zhanghubaobiao', 'icon-zhanghushezhi', 'icon-quanxianguanli', 'icon-caidanguanli', 'icon-jiaoseguanli', 'icon-quanxian', 'icon-quanxian1', 'icon-jiaoseguanli1', 'icon-zhanghaoquanxian', 'icon-jiaoseguanli2', 'icon-xitongguanli', 'icon-xitongguanli', 'icon-caidanguanli1', 'icon-houtaiguanli-quanxianguanli', 'icon-caidanguanli2', 'icon-xitongguanli_1', 'icon-shangpinziliaoshenhe', 'icon-genzongjilu', 'icon-shijuan1', 'icon-shujufenxi', 'icon-ios-paper', 'icon-biaoqian', 'icon-xiaoshoufenxi', 'icon-shangpinguanli', 'icon-fasong', 'icon-shangpinku', 'icon-a-ziyuan475', 'icon-jiedanjilumian', 'icon-jilumian', 'icon-wodeshangpin', 'icon-jifenguizeguankong2', 'icon-yijin06-jifenzhengce', 'icon-jindubaogao', 'icon-homefill', 'icon-renyuanxiaozu', 'icon-paiming', 'icon-shequ-active', 'icon-bumenrenyuanguanli', 'icon-jifenshangcheng', 'icon-zcpt-jifenguanli', 'icon-yonghutongji', 'icon-tongji', 'icon-jifenshangcheng1', 'icon-ziyuan', 'icon-ziyuan1', 'icon-ziyuan1', 'icon-shangcheng', 'icon-tongjifenxi', 'icon-renwu', 'icon-ziyuan174', 'icon-shequjiaozheng', 'icon-rank-full', 'icon-menu_xqgl', 'icon-sign-fill', 'icon-a-zaixianxuexikaoshipeixunkecheng-47', 'icon-a-zaixianxuexikaoshipeixunkecheng-56']
    };
  },
  created() {
    if (this.operateBox.operate == "edit") {
      this.ruleForm = Object.assign({}, this.operateBox.obj);
    }
  },
  methods: {
    submitForm: debounce(function (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.operateBox.operate == "add") {
            this.addMenu();
          }
          if (this.operateBox.operate == "edit") {
            this.editMenu();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }, 500),
    //新增菜单
    async addMenu() {
      let p = {
        menuName: this.ruleForm.menuName,
        visible: this.ruleForm.visible,
        menuType: this.ruleForm.menuType,
        parentId: this.ruleForm.belongMenu,
        parentName: "",
        orderNum: this.ruleForm.orderNum,
        path: this.ruleForm.path,
        icon: this.ruleForm.icon,
      };
      let obj = {};
      if (this.ruleForm.menuType != "M") {
        obj = this.mmenuOptions.find((item) => {
          //this.areaList 是通过接口请求下来的区域列表
          return item.value == this.ruleForm.belongMenu; //筛选出匹配数据
        });
        p.parentName = obj.label;
      }

      let res = await api.addMenu(p);
      if (res.code == 200) {
        this.$message.success("添加菜单成功");
        this.$emit("close");
      } else {
        this.$message.error(res.msg);
      }
    },
    //获取图标
    getIcon(item) {
      this.ruleForm.icon = item
    },
    //修改菜单
    async editMenu() {
      let p = {
        menuId: this.ruleForm.menuId,
        menuName: this.ruleForm.menuName,
        visible: this.ruleForm.visible,
        menuType: this.ruleForm.menuType,
        parentId: this.ruleForm.belongMenu,
        parentName: "",
        orderNum: this.ruleForm.orderNum,
        path: this.ruleForm.path,
        icon: this.ruleForm.icon,
      };
      let obj = {};
      if (this.ruleForm.menuType != "M") {
        obj = this.mmenuOptions.find((item) => {
          //this.areaList 是通过接口请求下来的区域列表
          return item.value == this.ruleForm.belongMenu; //筛选出匹配数据
        });
        p.parentName = obj.label;
      }
      let res = await api.editMenu(p);
      if (res.code == 200) {
        this.$message.success("修改菜单成功");
        this.$emit("close");
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style scoped lang="less">
.el-select {
  width: 100%;
}

.icon_box {
  display: flex;
  flex-wrap: wrap;

  span {
    font-size: 24px;
    margin: 5px;
  }

  span:hover {
    font-size: 30px;
    color: #0bf3c2;
  }
}
</style>
